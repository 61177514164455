.connection-error {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  background: #bf434d;
  text-align: center;
  padding: 0.3em 0;
  transform: translateY(-100%);
  transition: transform 200ms ease-in-out;

  &--active {
    transform: translateY(0);
  }
}
