.markdown-body .linked-heading {
  a {
    color: inherit;
    font-size: 15px;
    margin-left: -1.8em;
    display: inline-block;
    vertical-align: middle;
    opacity: 0;
  }

  .icon {
    margin-right: 0.8em;
  }

  a + span {
    vertical-align: middle;
  }

  &:hover a {
    opacity: 1;
    text-decoration: none;
  }
}
