@import '../../semantic/globals/site.variables';

.jump-target {
  // TODO: adjust as needed or per layout?
  padding-top: 110px !important;
  margin-top: -110px !important;
}

.jump-link {
  &--active {
    font-weight: bold !important;
  }
}
